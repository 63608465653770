<template>
    <AppPaginatedGrid ref="grid" :service="service" permission="gestaosesmt_cadastros_profissional_sesmt"
        newPath="/profissionais-sesmt/new" editPath="/profissionais-sesmt/" @onToggleMenu="onToggleMenu"
        :expander="false" subtitle="Profissionais SESMT" tooltip="Registro de profissionais e responsáveis SESMT"
        nomeTelaDoManual="profissionais-sesmt-list" origemHistoricoAcao="/profissionais-sesmt">
        <template #columns>
            <Column field="id" :sortable="true" sortField="prof.id" header="Cód." />
            <Column field="nome" :sortable="true" header="Profissional SESMT" />
            <Column field="classificacao" :sortable="true" header="Classificação" />
            <Column field="tipo" :sortable="true" header="Tipo" />
            <Column field="responsavel" :sortable="true" header="Responsável">
                <template #body="slotProps">
                    {{ formatarResponsavel(slotProps?.data?.responsavel) }}
                </template>
            </Column>
            <Column field="created_at" :sortable="true" sortField="prof.created_at" header="Criado">
                <template #body="slotProps">
                    {{ $filters.formatDate(slotProps.data.createdAt) }}
                    <small class="block text-500"> por {{ slotProps?.data?.userCreate?.name }} </small>
                </template>
            </Column>
            <Column field="updated_at" :sortable="true" sortField="prof.updated_at" header="Atualizado">
                <template #body="slotProps">
                    {{ $filters.formatDate(slotProps.data.updatedAt) }}
                    <small class="block text-500"> por {{ slotProps?.data?.userUpdate?.name }} </small>
                </template>
            </Column>
        </template>
    </AppPaginatedGrid>
</template>

<script>
import BaseService from '../../services/BaseService';
import { getCurrentCompany } from '../../services/store';

export default {
    data() {
        return {
            record: {}
        };
    },
    created() {
        this.service = new BaseService('/profissionais-sesmt');
    },
    computed: {
        title() {
            return this.$route.params.id ? 'Editar Profissionais' : 'Adicionar Profissionais`';
        },
        tenant() {
            return getCurrentCompany();
        }
    },
    watch: {
        tenant() {
            this.load();
        }
    },

    methods: {
        onToggleMenu(event, data) {
            this.record = data;
        },

        formatarResponsavel(responsavel) {
            if (responsavel === true) {
                return 'Sim'
            }
            return 'Não'
        },
    }
};
</script>

<style></style>
